import React from "react";
import { navigate } from "gatsby";

import { signOut } from "../../components/accountManagement";

const SignOutPage = () => {
  const onSignOut = () => {
    navigate("/");
  };

  signOut(onSignOut);

  return <></>;
};

export default SignOutPage;
